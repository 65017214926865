import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  connect() {
    let highest = 0

    this.elementTargets.forEach(element => {
      const elementHeight = element.clientHeight
                              - parseInt(getComputedStyle(element).paddingTop)
                              - parseInt(getComputedStyle(element).paddingBottom);

      if (elementHeight > highest) {
        highest = elementHeight
      }
    })

    this.elementTargets.forEach(element => {
      element.style.height = highest + 'px'
    })
  }
}
