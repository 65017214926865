import { Controller } from 'stimulus'
import StarRating from 'star-rating.js'

export default class extends Controller {
  static targets = ['stars']

  connect() {
    const stars = new StarRating(
      this.starsTarget,
      { tooltip: false }
    )
  }
}
