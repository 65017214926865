import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { updatedAt: Number }

  get localStorageKey() {
    return `popup_${this.updatedAtValue}_opened`
  }

  get isOpened() {
    return localStorage.getItem(this.localStorageKey) === 'true'
  }

  connect() {
    if (!this.isOpened) {
      this.element.classList.add('open')
      localStorage.setItem(this.localStorageKey, 'true')
    }
  }

  close() {
    this.element.classList.remove('open')
  }
}
