import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.updateRequired()
  }

  updateRequired() {
    const checkboxes = this.element.querySelectorAll('input[type=checkbox]')
    const hasCheckedCheckbox = [...checkboxes].some((checkbox) => checkbox.checked)

    checkboxes.forEach((checkbox) => {
      checkbox.required = !hasCheckedCheckbox
    })
  }
}
