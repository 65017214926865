// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Init stimulus & controllers
import '@hotwired/turbo-rails'
import { Application } from 'stimulus'
window.Stimulus = Application.start()

// Only load necessary stimulus controller to avoid unecessary JS loads.
import CheckboxController from 'controllers/checkbox_controller'
import EqualHeightController from 'controllers/equal-height_controller'
import PopupController from 'controllers/popup_controller'
import ReferencesController from 'controllers/references_controller'
import ToggleClassController from 'controllers/toggle-class_controller'

Stimulus.register('checkbox', CheckboxController)
Stimulus.register('equal-height', EqualHeightController)
Stimulus.register('popup', PopupController)
Stimulus.register('references', ReferencesController)
Stimulus.register('toggle-class', ToggleClassController)
