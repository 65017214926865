import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element', 'toggle']
  static values = { name: String, useCheckedValue: Boolean }

  connect() {
    if (this.hasToggleTarget) {
      this.toggleTarget.dispatchEvent(new CustomEvent('change', { bubbles: true }))
    }
  }

  toggle() {
    if (this.useCheckedValueValue) {
      event.currentTarget.classList.toggle(this.nameValue, this.toggleTarget.checked)
      this.elementTarget.classList.toggle(this.nameValue, this.toggleTarget.checked)
    } else {
      event.currentTarget.classList.toggle(this.nameValue)
      this.elementTarget.classList.toggle(this.nameValue)
    }
  }

  remove() {
    this.elementTarget.classList.remove(this.nameValue)
  }
}
